@import '@/styles/viewport';
@import '~@/styles/scrollbar.scss';

.preparationWrap {
  position: relative;

  width: 100%;

  .preparationItem {
    position: relative;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;
    padding: 12px;

    border-radius: 10px;
    background: #fff;

    &:nth-of-type(4) {
      margin-bottom: 24px;
    }
  }

  .preparationOrder {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;

    width: 28px;
    height: 28px;

    font-size: 14px;
    font-weight: 600;
    font-style: normal;

    color: #1b1f4d;
    border-radius: 50% 50%;
    background-color: #f5f5fc;
  }

  .preparationTxt {
    margin-right: 40px;
    margin-left: 12px;

    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 24px; /* 150% */

    letter-spacing: 0.5px;

    color: #1b1f4d;
  }

  .preparationLeft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .preparationBtn {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;

    padding: 4px 20px;

    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 22px; /* 157.143% */

    cursor: pointer;
    letter-spacing: 0.5px;

    border-width: 1px;
    border-style: solid;
    border-radius: 999px;
  }

  .completed {
    color: #c6c7d2;
    border: 1px solid #f0f1fc;
  }

  .uncompleted {
    color: #1b1f4d;
    border: 1px solid #dfe0ed;

    &:hover {
      background: #f7f8fa;
    }
  }

  .line {
    position: absolute;
    z-index: 0;
    top: 47px;
    left: 25px;

    width: 1px;
    height: 129px;

    border-radius: 0;
    background: #dfe0ed;
  }

  .result {
    // border-radius: 10px;
    // background-color: #fff;
    position: relative;
    z-index: 4;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 8px 12px 8px 6px;

    .resultLeft {
      display: flex;
      align-items: center;

      img {
        width: 48px;
      }

      p {
        margin-left: 16px;

        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        line-height: 24px; /* 150% */

        letter-spacing: 0.5px;
      }

      .enterClassTips {
        margin-top: 2px;

        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        line-height: 18px; /* 150% */

        letter-spacing: 0.5px;

        color: #8d8fa6;

        span {
          font-size: 12px;
          font-weight: 400;
          font-style: normal;
          line-height: 18px;

          letter-spacing: 0.5px;

          color: #ff5353;
        }
      }
    }
  }
}

.modalContent {
  width: 640px;

  @media (max-width: 720px) {
    width: auto;
  }

  h3 {
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    line-height: 24px; /* 160% */

    color: #1b1f4d;
  }

  .modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 12px 24px;

    cursor: pointer;

    border-bottom: 1px solid #ebecfa;
    border-radius: 10px 10px 0 0;

    .h3 {
      display: flex;
      align-items: center;

      > img {
        margin-right: 12px;
      }
    }
    @media (max-width: 720px) {
      padding: vw(12) vw(24);
      .h3 {
        font-size: vw(17);
      }
    }
  }
  .academicAdvisor {
    border-radius: 10px;
    width: 528px;
    height: 124px;
    padding: 16px;
    background-color: #f8f9ff;
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    .left {
      width: 276px;
      .avatarBox {
        display: flex;
        justify-content: flex-start;
        .avatar {
          width: 38px;
          height: 38px;
          margin-right: 12px;
          img {
            width: 100%;
            border-radius: 19px;
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .name {
            color: #1b1f4d;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
          }
          .title {
            color: #8d8fa6;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
          }
        }
      }
      .desc {
        margin-top: 16px;
        display: flex;
        justify-content: flex-start;
        color: #1b1f4d;
        font-size: 14px;
        font-weight: 500;
        .split {
          display: inline-block;
          width: 3px;
          height: 48px;
          background-color: #ebecfa;
          margin-right: 12px;
        }
      }
    }
    .right {
      .qrcodeBox {
        display: flex;
        justify-content: flex-start;
        .qrItem {
          width: 100px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin-left: 20px;

          .codeImg {
            width: 80px;
            height: 80px;
            padding: 10px;
            border-radius: 8px;
            border: 0.7px solid #ebecfa;
            background: #fff;
          }
          span {
            color: #8d8fa6;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            margin-top: 8px;
          }
        }
      }
    }
  }

  .modalBody {
    max-height: 435px;
    padding-top: 20px;
    padding-right: 40px;
    padding-left: 40px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 40px;
    @include scrollBar;

    @media (max-width: 720px) {
      height: auto;
      padding-bottom: 20px;
      padding-left: vw(24);
      padding-right: vw(24);
    }

    .p1 {
      margin-bottom: 12px;

      font-size: 14px;
      font-weight: 400;
      font-style: normal;
      line-height: 22px; /* 157.143% */

      color: #1b1f4d;
      @media (max-width: 720px) {
        font-size: vw(16);
      }
    }

    .mb8 {
      margin-bottom: 8px;
    }

    .p2 {
      margin-bottom: 8px;

      font-size: 14px;
      font-weight: 400;
      font-style: normal;
      line-height: 22px; /* 157.143% */

      color: #8d8fa6;
      @media (max-width: 720px) {
        font-size: vw(16);
      }

      b {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        line-height: 22px;

        color: #1b1f4d;
      }
    }

    .p3 {
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      line-height: 22px; /* 157.143% */

      color: #1b1f4d;
    }

    .p4 {
      font-size: 14px;
      font-weight: 400;
      font-style: normal;
      line-height: 20px;

      color: #8d8fa6;

      /* 166.667% */
      b {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        line-height: 20px;

        color: #484c70;
      }
    }

    .p4 {
      margin-top: 8px;
    }

    .p3 {
      margin-top: 12px;
    }

    .p5 {
      margin-top: 20px;

      font-size: 14px;
      font-weight: 400;
      font-style: normal;
      line-height: 22px; /* 157.143% */

      color: var(---font-03, #8d8fa6);
    }

    .detectDevice {
      display: flex;
      flex-direction: column;

      margin-top: 16px;
      padding: 16px;

      border-radius: 10px;
      background: #f5f5fc;

      .verificationConent {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 720px) {
          flex-direction: column;
        }
      }

      .detectDeviceLeft,
      .detectDeviceRight {
        display: flex;
        align-items: center;
      }

      .detectDeviceRight {
        @media (max-width: 720px) {
          margin-top: 20px;
        }
      }

      .detectDeviceLeft img {
        width: 16px;
        margin-right: 8px;
      }

      .detectDeviceLeft span {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        line-height: 22px; /* 157.143% */

        color: #1b1f4d;
      }

      .detectDeviceLeft .reget {
        // margin-left: 8px;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        line-height: 22px; /* 157.143% */

        cursor: pointer;

        color: #1b1f4d;

        > span {
          color: #ff5353;
        }
      }

      .detectDeviceRight {
        .verifyBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          user-select: none;

          height: 32px;
          margin-left: 12px;
          padding: 5px 24px;

          font-size: 14px;
          font-weight: 400;
          font-style: normal;
          line-height: 22px; /* 157.143% */

          color: #fff;
          border-radius: 20px;
          background: #ff5353;

          @media (max-width: 720px) {
            margin-left: 0;
          }
        }
      }
    }

    .repeatPost,
    .sendWaiting {
      margin-top: 16px;

      font-size: 14px;
      font-weight: 400;
      font-style: normal;
      line-height: 22px;

      color: #8d8fa6;

      /* 157.143% */
      span {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        line-height: 22px;

        cursor: pointer;

        color: #ff5353;
      }
    }

    .sendWaiting {
      span {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        line-height: 22px;

        color: #484c70;
      }
    }
  }

  .modalFooter {
    display: flex;
    align-items: center;
    justify-content: center;

    // margin-top: 40px;
    padding: 12px 24px;

    border-radius: var(--111, 0px) var(--111, 0px) 10px 10px;
    background: #f7f8fa;
  }

  .readingBtn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 200px;
    padding: 9px 28px;

    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;

    cursor: pointer;
    text-align: center;

    color: #fff;
    border: 0;
    border-radius: 30px;
    background: #ffcfd5;
  }

  .readedBtn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 200px;
    padding: 9px 28px;

    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;

    cursor: pointer;
    text-align: center;

    color: #fff;
    border: 0;
    border-radius: 30px;
    background: #ff5353;

    &:hover {
      background: #e54b4b;
    }
  }
}

.pb32 {
  padding-bottom: 32px;
}

.emailInputWrap {
  display: flex;

  margin-top: 20px;

  input {
    flex: 1;

    padding: 12px 20px;

    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 24px; /* 150% */

    letter-spacing: 0.5px;

    color: #1b1f4d;
    border: 0;
    border-radius: 24px;
    background: #f5f5fc;

    &::placeholder {
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      line-height: 24px; /* 150% */

      letter-spacing: 0.5px;

      color: #c6c7d2;
    }

    &:focus {
      outline: none;
    }
  }
}

.parentalStatusTitle {
  margin-bottom: 17px;

  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 22px; /* 157.143% */

  color: var(---font-01, #1b1f4d);
  @media (max-width: 720px) {
    font-size: vw(16);
  }
}

.errorString {
  margin-top: 8px;

  font-size: 14px;

  color: #ff5353;
}

.sendEmailFooter {
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 20px;
  padding-bottom: 40px;

  .sendEmailBtn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 560px;
    padding: 12px 28px;

    font-size: 16px;
    font-weight: 500;
    font-style: normal;

    cursor: pointer;
    text-align: center;

    color: #fff;
    border: 0;
    border-radius: 30px;
    background: #ff5353;

    &:hover {
      background: #e54b4b;
    }
  }

  .disabledBtn {
    cursor: not-allowed;

    background: var(---, #ffcfd5);

    &:hover {
      background: var(---, #ffcfd5);
    }
  }
}

.verifySuccess {
  .successHead {
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      width: 40px;
      margin-bottom: 8px;
    }

    p {
      font-family: 'PingFang SC';
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      line-height: 24px; /* 150% */

      text-align: center;

      color: #1b1f4d;

      font-feature-settings: 'clig' off, 'liga' off;
    }
  }

  .verifySuccessTitle {
    margin-top: 20px;
    margin-bottom: 24px;

    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px; /* 157.143% */

    text-align: center;

    color: #8d8fa6;
  }

  .verifySuccessFooter {
    display: flex;
    justify-content: center;

    margin-bottom: 8px;

    .sendEmailBtn {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 240px;
      padding: 12px 28px;

      font-size: 16px;
      font-weight: 500;
      font-style: normal;

      cursor: pointer;
      text-align: center;

      color: #fff;
      border: 0;
      border-radius: 30px;
      background: #ff5353;

      &:hover {
        background: #e54b4b;
      }
    }
  }
}

.problemOptions {
  width: 100%;

  list-style: none;

  > li {
    margin-bottom: 20px;
    padding: 12px;

    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px; /* 157.143% */

    cursor: pointer;

    color: #1b1f4d;
    border: 1px solid #dfe0ed;
    border-radius: 8px;

    &:hover {
      background: #f8f9ff;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.p_p1 {
  margin-bottom: 8px;

  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 22px; /* 157.143% */

  color: var(--2-1, #1b1f4d);
}

.p_p2 {
  margin-bottom: 8px;

  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 20px; /* 166.667% */

  color: var(---font-02, #484c70);

  &:last-child {
    margin-bottom: 0;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 20px;

    cursor: pointer;

    color: var(---, #ff5353);
  }

  .count_down {
    cursor: default;

    color: #8d8fa6;
  }
}

.seeEmailWrap {
  display: flex;
  align-items: center;

  margin-bottom: 8px;

  .seeEmail {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 24px;
    height: 24px;
    margin-left: 12px;

    cursor: pointer;

    border-radius: 12px;
    background: #f5f5fc;

    img {
      width: 16px;
    }
  }
}

.loadingContent {
  width: 100px;
  height: 100px;
}

.bracketContent {
  display: inline-flex;
  align-items: center;

  @media (max-width: 720px) {
    display: inline-block;
  }

  .refresh {
    margin-right: 4px;
    @media (max-width: 720px) {
      vertical-align: sub;
    }
  }
}

.rotate {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
