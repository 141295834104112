.modal-wrapper {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 99;
  top: 0;
  left: 0;
  background: rgba(65, 66, 77, 0.3);
  
  //&:before {
  //  content: '';
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  display: block;
  //  width: 100%;
  //  height: 100%;
  //  background: rgba(65, 66, 77, 0.3);
  //  backdrop-filter: blur(1px);
  //}
  .modal {
    width: 640px;
    background: #ffffff;
    border-radius: 10px;
    z-index: 100;
    
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      border-bottom: 1px solid #ebecfa;
      padding: 0 24px;
      color: #1b1f4d;
      font-size: 15px;
      font-weight: 600;
      box-sizing: border-box;
    }
    
    .close {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    
    .content-wrapper {
      padding: 20px 40px 48px 40px;
      .prompt-wrapper {
        padding: 14px 20px;
        background: rgb(255, 140, 31, 0.08);
        .prompt-title-container {
          display: flex;
          align-items: center;
          color: #ff8c1f;
          font-weight: 500;
          font-size: 14px;
          .prompt-title {
            margin-left: 12px;
          }
        }
        .prompt-description {
          color: #1b1f4d;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          padding-left: 28px;
        }
      }
      .operate-title {
        color: #1b1f4d;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        margin-top: 20px;
      }
      .operate-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        .operate-left {
          display: flex;
          box-sizing: border-box;
          flex-direction: column;
          justify-content: space-between;
          width: 278px;
          height: 122px;
          padding: 24px 24px 28px 20px;
          background-image: url('/imgs/pc/icon-ics-prompt-left.png');
          background-size: contain;
          background-repeat: no-repeat;
          &-title {
            color: #1b1f4d;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
          }
          .content-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #8d8fa6;
            span {
              display: -webkit-inline-box;
              width: 196px;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
              cursor: pointer;
            }
          }
        }
        .operate-right {
          color: #1b1f4d;
          display: flex;
          width: 278px;
          height: 122px;
          box-sizing: border-box;
          align-items: center;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          padding: 0 20px 0 35px;
          background-image: url('/imgs/pc/icon-ics-prompt-right.png');
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
      .note {
        color: #8d8fa6;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        margin-top: 20px;
      }
    }
    .footer {
      display: flex;
      width: 100%;
      height: 60px;
      justify-content: center;
      align-items: center;
      background: #f7f8fa;
      border-radius: 0px 0px 10px 10px;
      .btn {
        color: #FFFFFF;
        padding: 10px 24px;
        font-weight: 500;
        font-size: 12px;
        background: #ff5353;
        border-radius: 30px;
        cursor: pointer;
      }
    }
  }
}

.modal-enter {
  opacity: 0;
}
.modal-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.modal-exit {
  opacity: 1;
}
.modal-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}




