.divider {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  margin: 0 8px;
  height: 100%;
  color: #dfe0ed !important;
  &::before {
    content: '';
    width: 1px;
    height: 10px;
    background: #DFE0ED;
  }
}
