.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  height: 28px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 14px;
  white-space: nowrap;
  margin-left: 8px;
  letter-spacing: 0.5px;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  
  &.zh {
    padding: 0 12px;
  }
  
  &.default {
    color: #1b1f4d;
    border: 1px solid #ebecfa;
    &:hover {
      background: #F7F8FA;
    }
  }
  &.red-type {
    color: #ffffff;
    background-color: #ff5353;
  }
  &.primary {
    color: #ffffff;
    background-color: #484C70;
  }
  &.cancel {
    color: #484C70;
    background-color: #F5F5FC;
    border: none;
    &:hover {
      opacity: 0.8;
    }
  }
  &.processing {
    &::before {
      content: '';
      width: 12px;
      height: 12px;
      margin-right: 6px;
      background-image: url('/imgs/pc/processing-icon.gif');
      background-size: contain;
      background-position: center;
    }
  }
  &.disabled {
    color: #8d8fa6;
  }
}

.loading {
  margin-right: 10px;
  display: flex;
  align-items: center;
}
