@mixin scrollBar($scrollbarColor: #C6C7D2) {
  -webkit-tap-highlight-color: transparent;

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    /*滚动条里面小方块*/
    background: $scrollbarColor;
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 2px;
  }
}
