.video {
  width: 740px;
  height: 400px;
  border-radius: 20px;
  video {
    border-radius: 20px;
  }
}

.modal {
  border-radius: 50px;
  width: 780px;
}
.modal-body {
  padding: 18px;
  text-align: left;
}
