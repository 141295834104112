$colorFF5353: #ff5353;
$color009CFF: #009cff;

.status-wrapper {
  display: flex;
}

.more-box {
  position: relative;
  margin-left: 12px;
  img {
    cursor: pointer;
    padding: 0 3px;
  }
  &:hover {
    .more-wrapper {
      min-height: 60px;
      opacity: 1;
      z-index: 1;
      transition: all 0.3s ease-in-out;
    }
  }
}

.more-wrapper {
  z-index: -1;
  opacity: 0;
  min-height: 0px;
  transition: all 0.3s;
  position: absolute;
  right: -10px;
  top: 30px;
  width: auto;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.09);
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 16px;
  background-color: #fff;
  font-size: 18px;
  color: #666;
  font-weight: 500;
  user-select: none;
  &::after {
    content: '';
    position: absolute;
    width: 11px;
    height: 11px;
    background-color: #fff;
    top: -4px;
    right: 10px;
    transform: rotate(45deg);
    box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.09);
  }
}

.more-item {
  color: #1b1f4d;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  min-width: 56px;
  height: 28px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #ebecfa;
  border-radius: 14px;
  white-space: nowrap;
  margin-left: 8px;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  // transition: all 0.3s;
  &:hover {
    background-color: #eff0f2;
    transition: all 0.3s;
  }
  span {
    white-space: nowrap;
    // margin-left: 8px;
  }
}

.font-color-blue {
  color: $color009CFF;
}
.font-color-pink {
  color: $colorFF5353;
}

.countdown {
  font-size: 16px;
  display: flex;
  align-items: center;
  font-weight: normal;
  p {
    margin-left: 6px;
    color: #999;
  }
}

.absenter {
  color: #f2851d;
  font-size: 16px;
  font-weight: 400;
}

.completed {
  color: #ccc;
  font-size: 16px;
  font-weight: 400;
}

.normal {
  font-size: 16px;
  color: #ff5353;
  font-weight: 400;
  img {
    width: 16px;
    margin-right: 6px;
  }
}

.tooltip {
  margin-left: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 21px;
  img {
    width: 18px;
    height: 18px;
  }
}

.alert {
  // border: 12px solid #c0ecff;
  border-radius: 20px;
  margin-top: 0;
}

.modal-content {
  width: 340px;
  padding: 8px;
}

.modal-title {
  // margin-top: 6px;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  color: #1b1f4d;
}

.modal-tip {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #8d8fa6;
  word-break: break-word;

  span {
    color: #484c70;
    // font-weight: 600;
  }
}

.modal-sub-tip {
  display: flex;
  align-items: flex-start;
  margin-top: 12px;
  background: rgba(255, 246, 237);
  padding: 6px 10px;
  border-radius: 4px;

  .tip {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #fb755e;
    word-break: break-word;
    text-align: left;
  }
  &::before {
    content: '';
    display: inline-flex;
    width: 12px;
    height: 12px;
    background-image: url('/imgs/pc/icon-prompt-warning.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 10px;
    line-height: 18px;
    margin-top: 3px;
  }
}

.btn-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.button {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  border-radius: 20px;
  // margin-right: 36px;
  font-size: 16px;
  font-weight: 500;
  width: 136px;
  height: 40px;
  // &:last-child {
  //   margin-right: 0;
  // }
}

.submit {
  // background: #ff5353;
  // color: #ffffff;
  width: 136px;
  height: 40px;
  border-radius: 40px;
  user-select: none;
}
.cancel {
  // border: 1px solid #dfe0ed;
  // color: #1b1f4d;
  width: 136px;
  height: 40px;
  border-radius: 40px;
  user-select: none;
}

.join-guid {
  margin-right: 12px;
  color: #ff5353;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
}

.join-guide {
  display: flex;
  align-items: center;
  color: #1b1f4d;
  cursor: pointer;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  padding: 0 12px;
  height: 28px;
  margin-left: 8px;
  border: 1px solid #ebecfa;
  border-radius: 14px;
  &:hover {
    background-color: #eff0f2;
    transition: all 0.3s;
  }
  svg {
    margin-left: 4px;
  }
  img {
    position: absolute;
    left: -68px;
    width: 200px;
    top: 18px;
  }
}
